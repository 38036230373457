<template>
  <AuthenticatedLayout class="layout">
    <div
      ref="scroll"
      class="search-result"
      :class="mq.phone ? 'phone-search-result' : ''"
      @scroll="handleScroll"
    >
      <SearchFilters />
      <SearchResults />
    </div>
    <div
      v-if="isScrollTopDisplayed"
      class="scroll-top light-button"
      @click="scrollToTop"
    >
      <img
        src="@assets/arrow_up_white.svg"
      >
    </div>
  </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@layouts/AuthenticatedLayout'
import SearchFilters from '@/components/Search/SearchFilters'
import SearchResults from '@components/Search/SearchResults'

export default {
  name: 'Search',
  components: {
    AuthenticatedLayout,
    SearchFilters,
    SearchResults
  },
  inject: ['mq'],
  data () {
    return {
      currentScrollTop: 0,
      currentSearch: undefined,
      isScrollTopDisplayed: false
    }
  },
  created () {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        const query = this.$route.params.query ? decodeURIComponent(this.$route.params.query) : ''

        const params = {
          pageNb: 0
        }

        if (this.$route.query.filter) {
          params.filter = decodeURIComponent(this.$route.query.filter)
        }

        if (this.$route.query.startDate && this.$route.query.endDate) {
          params.startDate = decodeURIComponent(this.$route.query.startDate)
          params.endDate = decodeURIComponent(this.$route.query.endDate)
        }

        const search = {
          query,
          params
        }
        this.currentSearch = search
        this.$store.dispatch('search/searchQuery', search)
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  methods: {
    handleScroll (e) {
      const scroll = e.target
      this.isScrollTopDisplayed = (scroll.scrollTop > 1000)

      if (scroll.scrollTop > this.currentScrollTop) { // if we go down
        const nbPixelsBeforeBottom = scroll.scrollHeight - (scroll.scrollTop + scroll.clientHeight)

        // Load next page if search is not locked
        if (nbPixelsBeforeBottom === 0 && !this.$store.state.search.isLocked) {
          ++this.currentSearch.params.pageNb
          this.$store.dispatch('search/searchQuery', this.currentSearch)
        }
      }
      this.currentScrollTop = scroll.scrollTop
    },
    scrollToTop () {
      this.$refs.scroll.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.search-result {
  height: calc(100% - #{$desktop-search-bar-height});
  overflow-y: scroll;
  background-color: $dark-white-bg;
}

.phone-search-result {
  height: calc(100vh - #{$mobile-search-bar-height});

  .scroll-top {
    bottom: 60px;
    right: 15px;
  }
}

.scroll-top {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
</style>
