<template>
  <div
    v-if="activeFilter === 'maps'"
    style="height: 100%;max-width: 90%;margin: 1.5rem auto;"
  >
    <Map
      v-if="displayableResultList"
      class="media"
      :object-list="displayableResultList"
      @click.stop
    />
  </div>
  <template v-else>
    <SearchResult
      v-for="result in displayableResultList"
      :key="result.id"
      :result="result"
    />
    <Spinner v-if="displayableResultList === undefined" />
  </template>
</template>

<script>
import Map from '@components/Map/Map'
import SearchResult from '@components/Search/SearchResult'

export default {
  name: 'SearchResults',
  components: { SearchResult, Map },
  computed: {
    activeFilter () {
      return this.$route.query.filter ? decodeURIComponent(this.$route.query.filter) : 'all'
    },
    selectedId () {
      return this.$route.query.selected ? decodeURIComponent(this.$route.query.selected) : undefined
    },
    resultList () {
      return this.$store.state.search.resultList
    },
    displayableResultList () {
      if (this.resultList && this.selectedId) {
        const index = this.resultList.map(result => result.id).indexOf(this.selectedId)
        if (index === -1) {
          console.error('Cannot find result associated with id in url param (' + this.selectedId + ')')
          return this.resultList
        } else {
          return [this.resultList[index]]
        }
      } else {
        return this.resultList
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
