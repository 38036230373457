<template>
  <div class="block">
    <div class="filters">
      <template
        v-for="filter in filters"
        :key="filter.key"
      >
        <a
          href="javascript:void(0)"
          class="filter"
          :class="{'active': isActive(filter)}"
          :title="filter.label"
          @click="setFilter(filter)"
        >
          <img
            v-if="filter.img"
            :src="filter.img"
            :alt="filter.label"
          >
          <span v-else>{{ filter.label }}</span>
        </a>
      </template>
      <a
        v-t="'Search.tools'"
        href="javascript:void(0)"
        class="tools-toggle"
        @click="toggleToolDisplay"
      />
    </div>
    <hr>
    <div
      v-if="areToolsDisplayed"
      class="tools"
    >
      <Dropdown
        v-model="dateFilter"
        :list="dateOptions"
        :filter="false"
        :sort="false"
        display-field="label"
        @update:modelValue="setFilter()"
      />
      <template v-if="isCustomDateFilter">
        <span v-t="'Search.from'" />
        <Datepicker
          v-model="formattedStartDate"
          input-format="dd.MM.yy"
          :locale="locale"
          class="base-input datepicker"
        />
        <span v-t="'Search.to'" />
        <Datepicker
          v-model="formattedEndDate"
          input-format="dd.MM.yy"
          :lower-limit="formattedStartDate"
          :locale="locale"
          class="base-input datepicker"
        />
      </template>
    </div>
    <span>{{ $tc('Search.results', resultCount) }} ({{ $tc('Search.seconds', searchDuration) }})</span>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Datepicker from 'vue3-datepicker'
import { fr } from 'date-fns/locale'

export default {
  name: 'SearchFilters',
  components: { Datepicker },
  data () {
    return {
      areToolsDisplayed: false,
      dateFilter: undefined,
      dateOptions: [
        { label: this.$t('Search.noDateFilter') },
        { label: this.$t('Search.hourDateFilter'), startDate: dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().format('YYYY-MM-DD HH:mm:ss') },
        { label: this.$t('Search.dayDateFilter'), startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().format('YYYY-MM-DD HH:mm:ss') },
        { label: this.$t('Search.weekDateFilter'), startDate: dayjs().startOf('day').subtract(1, 'week').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss') },
        { label: this.$t('Search.monthDateFilter'), startDate: dayjs().startOf('day').subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss') },
        { label: this.$t('Search.yearDateFilter'), startDate: dayjs().startOf('day').subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss') },
        { label: this.$t('Search.customDateFilter'), startDate: dayjs().startOf('day').subtract(1, 'week').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss') }
      ],
      filters: [
        { key: 'all', label: this.$t('Search.all') },
        { key: 'maps', label: this.$t('Search.map'), img: require('@/assets/menu/icon_pin.svg') },
        { key: 'dashboard', label: this.$t('router.views.DashBoard.title'), img: require('@/assets/menu/menu_dashboard_black.svg') },
        { key: 'documents', label: this.$t('router.views.Documents.title'), img: require('@/assets/menu/menu_portfolio_black.svg') },
        { key: 'messaging', label: this.$t('router.views.Messaging.title'), img: require('@/assets/menu/menu_messaging_black.svg') },
        { key: 'communities', label: this.$t('router.views.Communities.title'), img: require('@/assets/menu/menu_commu_black.svg') },
        { key: 'contacts', label: this.$t('router.views.Contacts.title'), img: require('@/assets/menu/menu_contacts_black.svg') },
        { key: 'indicators', label: this.$t('router.views.Indicators.title'), img: require('@/assets/menu/menu_indicators_black.svg') },
        { key: 'news', label: this.$t('News.title'), img: require('@/assets/menu/menu_news_black.svg') }
      ],
      locale: fr
    }
  },
  computed: {
    activeFilter () {
      return this.$route.query.filter ? decodeURIComponent(this.$route.query.filter) : 'all'
    },
    formattedEndDate: {
      get () {
        return dayjs(this.dateOptions[this.dateOptions.length - 1].endDate).toDate()
      },
      set (date) {
        this.dateOptions[this.dateOptions.length - 1].endDate = dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.setFilter()
      }
    },
    formattedStartDate: {
      get () {
        return dayjs(this.dateOptions[this.dateOptions.length - 1].startDate).toDate()
      },
      set (date) {
        this.dateOptions[this.dateOptions.length - 1].startDate = dayjs(date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.setFilter()
      }
    },
    isCustomDateFilter () {
      return (this.dateOptions.indexOf(this.dateFilter) === this.dateOptions.length - 1)
    },
    resultCount () {
      return Number(this.$store.state.search.resultCount)
    },
    searchDuration () {
      return Number(this.$store.state.search.searchDuration)
    }
  },
  created () {
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateFilter = this.dateOptions[this.dateOptions.length - 1]
      this.dateOptions[this.dateOptions.length - 1].startDate = decodeURIComponent(this.$route.query.startDate)
      this.dateOptions[this.dateOptions.length - 1].endDate = decodeURIComponent(this.$route.query.endDate)
    } else {
      this.dateFilter = this.dateOptions[0]
    }
  },
  methods: {
    isActive (filter) {
      return (this.activeFilter === filter.key)
    },
    setFilter (filter) {
      // Empty search results
      this.$store.commit('search/setResultList', undefined)
      const params = { query: {} }

      if (filter) {
        params.query.filter = encodeURIComponent(filter.key)
      } else if (this.activeFilter !== 'all') {
        params.query.filter = encodeURIComponent(this.activeFilter)
      }

      // Date management
      if (this.dateFilter && this.dateFilter.startDate && this.dateFilter.endDate) {
        params.query.startDate = encodeURIComponent(this.dateFilter.startDate)
        params.query.endDate = encodeURIComponent(this.dateFilter.endDate)
      }

      // Go to filter
      this.$router.push(params)
    },
    toggleToolDisplay () {
      this.areToolsDisplayed = !this.areToolsDisplayed
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.block {
  width: 90%;
  padding: 0.6rem;
  margin: 1.5rem auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);

  a {
    color: inherit;
    text-decoration: none;
  }

  hr {
    margin-top: 0;
    border-color: $color-menu-bg;
  }
}
.filters {
  display: flex;
  align-items: center;
  overflow: auto;
}

.filter {
  margin: 0.3rem 0.3rem 0 0.3rem;
  border-bottom: 4px solid #fff;

  img {
    height: 35px;
    padding: 0.2rem;
    border-radius: $border-radius;
  }
}

.tools-toggle {
  margin-left: auto;
}

.tools {
  display: flex;
  align-items: center;

  span {
    margin: 0 1rem;
  }
}

.active {
  border-color: $color-menu-selected-item;
}
</style>
