<template>
  <div
    class="block"
    :class="{'is-clickable': allowRedirection}"
    @click="handleRedirection"
  >
    <span class="pull-right">{{ $t('Search.resultType.' + result.type) + formattedDetails }}</span>
    <h4 class="header">
      {{ result.title }}
    </h4>
    <p>{{ result.content }}</p>
    <span>{{ getFormattedDate(result.date) }}</span>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  messagingDraftFolderType,
  messagingInboxFolderType,
  messagingSentFolderType,
  messagingTrashFolderType
} from '@/constants/appConstants'

export default {
  name: 'SearchResult',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  computed: {
    allowRedirection () {
      return this.result.type === 'document' ||
          this.result.type === 'community' ||
          this.result.type === 'contactList' ||
          this.result.type === 'indicator' ||
          this.result.type === 'formation' ||
          this.result.type === 'etablissement' ||
          this.result.type === 'messaging'
    },
    formattedDetails () {
      if (this.result.type === 'messaging' && this.result.folderType) {
        switch (this.result.folderType) {
          case messagingInboxFolderType:
            return ' (' + this.$t('router.views.Messaging.inbox') + ')'
          case messagingDraftFolderType:
            return ' (' + this.$t('router.views.Messaging.drafts') + ')'
          case messagingSentFolderType:
            return ' (' + this.$t('router.views.Messaging.sent') + ')'
          case messagingTrashFolderType:
            return ' (' + this.$t('router.views.Messaging.trash') + ')'
        }
      }
      return ''
    }
  },
  methods: {
    getFormattedDate (date) {
      return dayjs(date).format('DD.MM.YYYY')
    },
    handleRedirection () {
      const params = { query: {} }
      const mapFilter = { key: 'maps', label: this.$t('Search.map'), img: require('@/assets/menu/icon_pin.svg') }

      if (this.allowRedirection) {
        switch (this.result.type) {
          case 'formation':
          case 'etablissement':
            params.query.filter = encodeURIComponent(mapFilter.key)
            params.query.selected = this.result.id

            this.$store.commit('search/setResultList', undefined)
            this.$router.push(params)
            break
          case 'community':
            this.$router.push({
              name: 'communities',
              params: { communityId: this.result.id }
            })
            break
          case 'contactList':
            this.$router.push({
              name: 'contacts',
              params: { listId: this.result.id }
            })
            break
          case 'document':
            if (this.result.object.type === 'Folder') {
              this.$router.push({
                name: 'portfolio',
                params: { folderId: this.result.id }
              })
            } else if (this.result.object.type === 'File') {
              this.$router.push({
                name: 'portfolio',
                params: { folderId: this.result.object.folderId }
              })
            }
            break
          case 'indicator':
            this.$router.push({
              name: 'indicator',
              params: { indicatorId: this.result.id }
            })
            break
          case 'messaging':
            this.$router.push({ name: 'messaging' })
            break
          default:
            console.error('no redirection exist for type' + this.result.type)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.block {
  width: 90%;
  padding: 0.6rem;
  margin: 1.5rem auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);

  &.is-clickable {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-wrap: anywhere;
    $numLines: 2;
    $lineHeight: 1.3;
    -webkit-line-clamp: $numLines;
    max-height: $numLines * $lineHeight + unquote('rem');
  }
}

.pull-right {
  float: right;
}

.header {
  max-width: 75%;
  margin-top: 0;
  margin-bottom: 0.5rem;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
